//import $ from 'jquery';

import 'jquery.mb.ytplayer/'
import 'jquery.mb.ytplayer/dist/css/jquery.mb.YTPlayer.min.css';  

import './../../scss/blocks/block-banner.scss';

// Získajte odkaz na element s id "player"
var playerElement = document.getElementById("player");
if (playerElement !== null) {
  setTimeout(function () {
    jQuery("#player").YTPlayer();
  }, 1000)
}

/* $(document).ready(function() {

  
}) */